import Modern from '../../images/invest/Modern.png';
import HighQuality from '../../images/invest/High-Quality.png';
import Connected from '../../images/invest/Connected.png';

export const circleData = [
  {
    id: 1,
    image: Modern,
    text:
      'We have built technology to dramatically reduce cost and to bring full-spectrum care to all Canadians without wait-times.',
  },
  {
    id: 2,
    image: HighQuality,
    text:
      'Our platform helps clients identify the best mental health care providers for their needs.',
  },
  {
    id: 3,
    image: Connected,
    text:
      'Inkblot has built strong partnerships in the healthcare, insurance and benefits industries.',
  },
];
