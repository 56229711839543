import React from 'react';
import _ from 'lodash';
import InvestSectionContainer from '../../components/InvestSectionContainer';
import { circleData } from '.';

const SectionTwo = () => (
  <InvestSectionContainer container num={2}>
    <div className="row center">
      {_.map(circleData, ({ id, image, text }) => (
        <div key={id} className="col-4 center">
          <div className="circle">
            <img src={image} alt="" />
          </div>
          <p
            className="size-22"
            style={{
              marginTop: '34px',
              marginBottom: '72px',
              lineHeight: 1.2,
            }}
          >
            {text}
          </p>
        </div>
      ))}
    </div>
  </InvestSectionContainer>
);

export default SectionTwo;
