import React from 'react';
import PropTypes from 'prop-types';

const InvestSectionContainer = ({
  children, num, style, container, containerStyle,
}) => (
  <div style={style} className={`invest-section-container  section-${num}`}>
    <div className={container ? 'container' : ''} style={containerStyle}>
      {children}
    </div>
  </div>
);

InvestSectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
  num: PropTypes.number.isRequired,
  style: PropTypes.object,
  container: PropTypes.bool,
  containerStyle: PropTypes.object,
};

InvestSectionContainer.defaultProps = {
  style: {},
  container: false,
  containerStyle: {},
};

export default InvestSectionContainer;
