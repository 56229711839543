import React from 'react';
import InvestSectionContainer from '../../components/InvestSectionContainer';
import DoctorWithLines from '../../images/_doctor_with_lines.png';

const SectionThree = () => (
  <InvestSectionContainer num={3} style={{ minHeight: '0px' }}>
    <div className="lolDoc">
      <img className="doctor" src={DoctorWithLines} alt="doctor" />
      <div className="right-side">
        <p className="size-20">
          Physicians across Canada are our top referral source. They often ask us about investment
          opportunities. We’ve decided to raise our first round of outside capital from our most
          crucial referral source and provide direct-ownership and the ability to shape the future
          of Inkblot to Canadian physicians. <br /> <br />Inkblot is an Ontario-based,
          private, for-profit corporation that has been funded to date by individual Canadian
          investors.{' '}
          <b style={{ color: 'inherit' }}>
            We are a majority physician-owned company which we intend to maintain with this round of
            investment.
          </b>
        </p>
      </div>
    </div>
  </InvestSectionContainer>
);

export default SectionThree;
