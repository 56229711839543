/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import axios from 'axios';
import InvestSectionContainer from '../../components/InvestSectionContainer';
import { SERVER_URL } from '../../utils/environment';

class SectionFour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendEmailButton: "LET'S GET IN TOUCH",
      sendEmailDisabled: false,
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  sendEmail = values => {
    axios.post(`${SERVER_URL}/api/investor_emails/`, values).then(() => {
      this.setState({ sendEmailButton: 'EMAIL SENT!', sendEmailDisabled: true });
    });
  };
  handleSubmit = event => {
    event.preventDefault();
    this.sendEmail({ fields: this.state });
  };

  render() {
    return (
      <InvestSectionContainer num={4}>
        <div style={{ backgroundColor: '#2e5fca', width: '100%', height: '72px' }} />
        <div id="information">
          <div className="health-future">
            <p>Ready to invest in a healthier future?</p>
            <p>
              If you would like to learn more,{' '}
              <b style={{ color: 'inherit' }}>
                please fill out the confidential form below to receive details about our business,
                financials, partnerships, and the investment opportunity.{' '}
              </b>
              We will follow up with an email detailing Inkblot and schedule a meeting for you to
              speak to our founders.
            </p>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="form-section">
              <div className="input-block">
                <p>First Name</p>
                <input
                  onChange={value => this.handleChange(value)}
                  name="first_name"
                  value={this.state.first_name}
                  type="text"
                  required
                />
              </div>
              <div className="input-block">
                <p>Last Name</p>
                <input
                  onChange={value => this.handleChange(value)}
                  name="last_name"
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="form-section">
              <div className="input-block">
                <p>Email Address</p>
                <input
                  onChange={value => this.handleChange(value)}
                  name="email"
                  type="email"
                  required
                />
              </div>
              <div className="input-block">
                <p>Do you meet criteria for being an accredited investor*?</p>
                <div className="form-radio-section">
                  <div className="radio-format">
                    <input
                      onChange={value => this.handleChange(value)}
                      type="radio"
                      name="accredited"
                      id="yes"
                      value="yes"
                      required
                    />
                    <label htmlFor="yes">Yes</label>
                  </div>
                  <div className="radio-format">
                    <input
                      onChange={value => this.handleChange(value)}
                      type="radio"
                      name="accredited"
                      id="no"
                      value="no"
                      required
                    />
                    <label htmlFor="no">No</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="input-block" style={{ marginRight: '0' }}>
              <p>Comments (Optional)</p>
              <textarea onChange={value => this.handleChange(value)} name="comments" type="text" />
            </div>
            <button
              style={{
                marginTop: '70px',
                backgroundColor: this.state.sendEmailDisabled ? '#B2FF59' : '',
              }}
              type="submit"
              disabled={this.state.sendEmailDisabled}
            >
              {this.state.sendEmailButton}
            </button>
          </form>
          <div className="eligibility size-16">
            <b>
              *Investment eligibility requires an investee to meet one or more of the following
              requirements:
            </b>
            <ul style={{ marginTop: '20px' }}>
              <li>
                Net income before taxes was more than $200,000 in each of the two most recent
                calendar years and is expected to be more than $200,000 in the current calendar
                year.
              </li>
              <li>
                Net income before taxes combined with a spouse was more than $300,000 in each of the
                two most recent calendar years, and their combined net income is expected to be more
                than $300,000 in the current calendar year.
              </li>
              <li>
                Financial assets, alone or with a spouse, of more than $1 million before taxes but
                net of related liabilities. Net assets, alone or with a spouse, worth more than $5
                million.
              </li>
            </ul>
          </div>
        </div>
        <div style={{ backgroundColor: '#2e5fca', height: '121px' }} />
      </InvestSectionContainer>
    );
  }
}

export default SectionFour;
