import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SectionOne from '../pagePartials/invest/SectionOne';
import SectionTwo from '../pagePartials/invest/SectionTwo';
import SectionThree from '../pagePartials/invest/SectionThree';
import SectionFour from '../pagePartials/invest/SectionFour';

const Invest = props => (
  <Layout location={props.location}>
    <div className="invest-page">
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
    </div>
  </Layout>
);

Invest.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Invest;
