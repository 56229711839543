import React from 'react';
import InvestSectionContainer from '../../components/InvestSectionContainer';
import Logo from '../../images/invest/Logo.png';
import Header from '../../images/invest/Header.png';

const SectionOne = () => (
  <InvestSectionContainer container num={1}>
    <img className="logo" src={Logo} alt="" />

    <div className="text center">
      <p className="size-36" style={{ fontWeight: 'bold', marginTop: '80px' }}>
        Invest in accessible, high-quality mental health services for your population.
      </p>
      <p className="size-20" style={{ paddingTop: '40px' }}>
        <b style={{ color: 'inherit' }}>Inkblot</b> is Canada&#39;s only online marketplace for
        mental health and addiction services.
      </p>
    </div>

    <a href="#information">
      <button className="request-information">REQUEST INFORMATION</button>
    </a>

    <div className="spiral-background">
      <img src={Header} alt="" style={{ background: 'white' }} />
    </div>
  </InvestSectionContainer>
);

export default SectionOne;
